const carouselStyle = (theme) => ({
  section: {
    padding: "0px",
  },
  container: {
    maxWidth: "40vw",
    margin: "auto",

    [theme.breakpoints.down("xs")]: {
      maxWidth: "90vw",
    },
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
  },
  slickImage: {},
});

export default carouselStyle;
