import React from "react";

import teamStyle from "assets/jss/material-kit-react/views/componentsSections/teamStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(teamStyle);

const Team = ({ team }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.fixedImage} />
      <h2 className={classes.title}>Meet the Team</h2>
      <p className={classes.subtitle}>
        In addition to a huge family of fosters, transporters, and donors, our
        rescue partners work behind the scenes to ensure the care and safety of
        all our animals.
      </p>
      <div className={classes.teamImageContainer}>
        {team.map((e, i) => (
          <div className={classes.teamImageHolder} key={i}>
            <div
              style={{ backgroundImage: `url('${e.imgur_url}')` }}
              className={classes.teamImage}
            />
            <p className={classes.teamCaption}>{e.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Team;
