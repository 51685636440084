import React from "react";
import styles from "assets/jss/material-kit-react/views/componentsSections/contactStyle.js";
import Email from "@material-ui/icons/Email";
import LocalPhone from "@material-ui/icons/LocalPhone";

import { makeStyles } from "@material-ui/core/styles";
import Button from "components/CustomButtons/Button.js";

const useStyles = makeStyles(styles);

const Contact = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Get in Touch</h2>
      <div className={classes.content}>
        <Button color="primary" href="tel:+16194310468" large>
          <LocalPhone className={classes.icons} /> Tel: (619) 431-0468
        </Button>

        <Button color="info" href="mailto:passionforpittiessd@gmail.com" large>
          <Email className={classes.icons} /> Email:
          passionforpittiessd@gmail.com
        </Button>
      </div>
      <div className={classes.addressText}>
        <p>Passion For Pitties</p>
        <p>PO Box 212574</p>
        <p>Chula Vista, CA 91921-2574</p>
      </div>
    </div>
  );
};
export default Contact;
