import React from "react";

import styles from "assets/jss/material-kit-react/views/componentsSections/spotlightStyle";
import { makeStyles } from "@material-ui/core/styles";
import { ModalContent } from "components/Dog/DogProfileModal";

import VolumeUp from "@material-ui/icons/VolumeUp";

const useStyles = makeStyles(styles);

const Spotlight = ({ spotlight, formUrl }) => {
  const classes = useStyles();
  const [dog] = spotlight;
  const images = dog.photos.map((e) => e.large);

  return (
    <div>
      <div
        className={classes.spotlightBg}
        style={{ backgroundImage: `url(${images[0]})` }}
      />
      <div className={classes.container}>
        <h2 className={classes.title}>
          <VolumeUp /> Fur Spotlight: {dog.name}
        </h2>
        <p className={classes.content}>Our doggo of the month!</p>
        <div className={classes.photos}>
          <ModalContent dog={dog} formUrl={formUrl} ctx="adopt" />
        </div>
      </div>
    </div>
  );
};

export default Spotlight;
