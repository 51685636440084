import { container } from "assets/jss/material-kit-react.js";

const storyStyle = {
  container: {
    ...container,
    position: "relative",
    paddingTop: "10vh",
    paddingBottom: "10vh",
  },
  title: {
    fontFamily: '"chelsea market", fantasy',
  },
  content: {
    paddingTop: "10vh",
    fontSize: "16px",
    width: "100%",
    textAlign: "center",
  },
  textBlock: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  addressText: {
    fontSize: "16px",
    paddingTop: "2vh",
    textAlign: "center",
  },
};

export default storyStyle;
