import { container } from "assets/jss/material-kit-react";

const happyTailsStyle = {
  container,
  title: {
    fontFamily: '"chelsea market", fantasy',
    paddingTop: "20px",
  },
  subtitle: {
    fontSize: "16px",
  },
  photos: {
    display: "flex",
    justifyContent: "center",
  },
};

export default happyTailsStyle;
