import React from "react";

import styles from "assets/jss/material-kit-react/views/componentsSections/storyStyle.js";
import { roseColor } from "assets/jss/material-kit-react";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const Story = ({ donateAction }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Our Story</h2>
      <div>
        <p className={classes.content}>
          It all began in 2011 with a beautiful American Pit Bull Terrier named
          Sage. She was a gorgeous white and fawn girl with the most majestic
          green eyes and the cutest pink spotted nose. Our founder, Elise,
          volunteered at the shelter five days a week for 5 hours a day to help
          the dogs see another day. Sage was a stray who had been living at the
          Chula Vista Animal Care Facility for over four months. In that time,
          no one came to adopt her - no one came to rescue her. Elise built a
          wonderful relationship with Sage and loved her immensely.
        </p>
        <p className={classes.content}>
          One day, Elise went in to exercise Sage, but she was missing. Elise
          frantically searched every kennel, but couldn’t find her anywhere.
          Could she have finally been adopted? An employee finally told Elise
          the truth - that Sage’s time was up. The staff had evaluated her
          behavior and the poor sweetheart had lost some of the softness that
          she once had with other dogs. Life in a kennel had broken her spirit.
          Elise’s heart was shattered.
        </p>
        <p className={classes.content}>
          The end of Sage’s story was the beginning of ours. Elise swore that
          her life would not be in vain. American Pit Bull Terriers are our
          passion and our cause. We advocate tirelessly for bully breeds through
          education, events, and fundraisers. Now, our main focus is pulling
          more dogs like Sage from the shelter before their spirits are broken.
          We believe that any dog can be rehabilitated with positive training,
          reinforcement, and gentle human touch and guidance. All dogs ask for
          in return is love and kindness.
        </p>
        <p className={classes.content}>
          Pitties are loving, soft, gentle, smart, playful, loyal dogs who long
          for human companionship and deserve a family to love and be loved by.
          In honor of Sage, we vow to pull as many dogs into our rescue as
          possible and:
        </p>
        <p className={classes.content}>
          <p className={classes.content}>
            <strong style={{ color: roseColor }}>S</strong>
            top
          </p>
          <p className={classes.content}>
            <strong style={{ color: roseColor }}>A</strong>
            nimals
          </p>
          <p className={classes.content}>
            <strong style={{ color: roseColor }}>G</strong>
            etting
          </p>
          <p className={classes.content}>
            <strong style={{ color: roseColor }}>E</strong>
            uthanized
          </p>
        </p>
        <p className={classes.content}>
          Passion for Pitties is a 501(c)(3)non-profit organization. Please
          consider <a onClick={donateAction}>tax deductible donations</a> to
          aide us in saving and advocating for bully breed dogs in Southern
          California and beyond.
        </p>
      </div>
    </div>
  );
};

export default Story;
