import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Favorite from "@material-ui/icons/Favorite";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/dogStyle.js";
import { infoColor, roseColor } from "assets/jss/material-kit-react";

const useStyles = makeStyles(styles);

const DogCard = ({ dog, setClassicModal, setSelectedDog, ctx }) => {
  const classes = useStyles();

  const { name, gender, primary_photo_cropped } = dog;

  const selectDogAndOpenModal = (dog) => {
    setClassicModal(true);
    return setSelectedDog(dog);
  };

  const theme =
    gender === "Male"
      ? { name: "info", color: infoColor }
      : { name: "rose", color: roseColor };

  return (
    <div
      className={classes.dogCards}
      onClick={() => selectDogAndOpenModal(dog)}
    >
      <div
        style={{ backgroundImage: `url(${primary_photo_cropped?.large})` }}
        className={classes.dogBackgroundImage}
      ></div>

      <Button
        onClick={() => selectDogAndOpenModal(dog)}
        className={classes.dogPick}
        justIcon
        round
        color={theme.name}
      >
        <Favorite className={classes.icons} />
      </Button>
      <div
        className={classes.dogCardContent}
        style={{ background: theme.color }}
      >
        <p className={classes.textName}>
          <span className={classes.dogGender}>
            {gender === "Male" ? (
              <i className="fas fa-mars" />
            ) : (
              <i className="fas fa-venus" />
            )}
          </span>
          <div className="typography-line">
            <span>{name}</span>
          </div>
        </p>
        <Button
          size="sm"
          onClick={() => selectDogAndOpenModal(dog)}
          color="white"
          round
        >
          {ctx}
        </Button>
      </div>
    </div>
  );
};

export default DogCard;
