import { container } from "assets/jss/material-kit-react.js";

const partnersStyle = {
  container: {
    ...container,
    paddingTop: "4vh",
    zIndex: "2",
    position: "relative",
  },
  title: {
    fontFamily: '"chelsea market", fantasy',
  },
  subtitle: {
    fontSize: "16px",
  },
  photos: {},
  card: {
    padding: "20px",
    height: "260px",
    cursor: "pointer",
  },
  img: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  name: {
    marginTop: "4px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "16px",
  },
};

export default partnersStyle;
