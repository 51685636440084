import React from "react";

import { ReactPhotoCollage } from "react-photo-collage";

import styles from "assets/jss/material-kit-react/views/componentsSections/happyTailsStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const HappyTails = ({ imgs }) => {
  const classes = useStyles();
  const setting = {
    width: "80vw",
    height: ["450px", "170px"],
    layout: [1, 4],
    photos: imgs?.map((e) => ({ source: e?.imgur_url })).filter((e) => e),
    showNumOfRemainingPhotos: true,
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Happy Tails</h2>
      <p className={classes.subtitle}>Our very own Succcess Stories!</p>
      <div className={classes.photos}>
        <ReactPhotoCollage {...setting} />
      </div>
    </div>
  );
};

export default HappyTails;
