import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/eventsStyle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Slide from "@material-ui/core/Slide";
import classNames from "classnames";

const useStyles = makeStyles(styles);

// const openLinkIfExists = link => link && window.open(link, "_blank");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Events = ({ events = [] }) => {
  const classes = useStyles();
  const [openEventModal, setOpenEventModal] = React.useState(false);
  const [eventModalContent, setEventModalContent] = React.useState([]);
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  if (!events.length) {
    return null;
  }
  return (
    <div className={classes.container}>
      <Modal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={openEventModal}
        TransitionComponent={Transition}
        fullScreen
        className={classes.eventModal}
        onClose={() => setOpenEventModal(false)}
      >
        <div>
          <h2 id="unstyled-modal-title">{eventModalContent?.title}</h2>
          <p id="unstyled-modal-description">
            {eventModalContent?.description}
          </p>
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => setOpenEventModal(false)}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <div
            className={classNames(classes.img, classes.modalEventImg)}
            style={{ backgroundImage: `url("${eventModalContent.imgur_url}")` }}
          />
        </div>
      </Modal>
      <h2 className={classes.title}>Events</h2>
      <p className={classes.subtitle}>Meetups &amp; Fundraisers.</p>
      <div className={classes.photos}>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlay
          autoPlaySpeed={5000}
          keyBoardControl
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {events.map((e, i) => (
            <Card
              key={i}
              carousel
              className={classes.card}
              onClick={() => setOpenEventModal(true) || setEventModalContent(e)}
            >
              <div
                className={classes.img}
                style={{ backgroundImage: `url("${e.imgur_url}")` }}
              />
              <div className={classes.name}>{e.name}</div>
            </Card>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Events;
