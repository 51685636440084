import { container } from "assets/jss/material-kit-react.js";

const storyStyle = {
  container: {
    ...container,
    zIndex: "2",
    position: "relative",
  },
  title: {
    fontFamily: '"chelsea market", fantasy',
  },
  content: {
    fontSize: "16px",
  },
};

export default storyStyle;
