import React from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "components/Card/Card.js";

import styles from "assets/jss/material-kit-react/views/componentsSections/partnersStyle";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(styles);

const openLinkIfExists = (link) => link && window.open(link, "_blank");

const Partners = ({ partners }) => {
  const classes = useStyles();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  if (!partners.length) {
    return null;
  }
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Rescue Partners</h2>
      <p className={classes.subtitle}>Organizations that work with us.</p>
      <div className={classes.photos}>
        <Carousel
          swipeable={false}
          draggable={false}
          showDots
          responsive={responsive}
          ssr // means to render carousel on server-side.
          infinite
          autoPlay
          autoPlaySpeed={5000}
          keyBoardControl
          transitionDuration={500}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {partners.map((e, i) => (
            <Card
              key={i}
              carousel
              className={classes.card}
              onClick={() => openLinkIfExists(e.link)}
            >
              <div
                className={classes.img}
                style={{ backgroundImage: `url("${e.imgur_url}")` }}
              />
              <div className={classes.name}>{e.name}</div>
            </Card>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Partners;
