import React from "react";
import styles from "assets/jss/material-kit-react/views/componentsSections/dogStyle.js";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

import Button from "components/CustomButtons/Button.js";
import DogCard from "./DogCard";
import DogProfileModal from "./DogProfileModal";
import { roseColor } from "assets/jss/material-kit-react";
import { infoColor } from "assets/jss/material-kit-react";
import { ExpandMore } from "@material-ui/icons";

const useStyles = makeStyles(styles);

const DogList = ({ ctx, animals, title, formUrl }) => {
  const [classicModal, setClassicModal] = React.useState(false);
  const [lazyLoadCount, setLazyLoadCount] = React.useState(9);
  const [selectedDog, setSelectedDog] = React.useState({});
  const classes = useStyles();

  const animalsFiltered = animals.slice(0, lazyLoadCount);

  return (
    <div className={classNames(classes.container, classes.adoptionSection)}>
      <h2
        className={classes.dogListTitle}
        style={{ color: ctx === "adopt" ? roseColor : infoColor }}
      >
        {title}
      </h2>
      <DogProfileModal
        classicModal={classicModal}
        setClassicModal={setClassicModal}
        dog={selectedDog}
        ctx={ctx}
        formUrl={formUrl}
      />
      <div className={classes.dogList}>
        {animalsFiltered.map((e, i) => (
          <DogCard
            dog={e}
            key={i}
            ctx={ctx}
            setSelectedDog={setSelectedDog}
            setClassicModal={setClassicModal}
          />
        ))}
      </div>
      {animalsFiltered.length !== animals.length && (
        <div className={classes.viewMoreButton}>
          <Button
            onClick={() => setLazyLoadCount(animals.length)}
            className={classes.viewMoreButtonText}
            color="transparent"
          >
            <ExpandMore /> View more
          </Button>
        </div>
      )}
    </div>
  );
};

export default DogList;
