import { container } from "assets/jss/material-kit-react";
import { successColor } from "assets/jss/material-kit-react";

import image from "assets/img/puppy-background.png";

const teamStyles = {
  container,
  teamImageContainer: {
    display: "flex",
    flexDirection: "row",
    flexFlow: "wrap",
    justifyContent: "center",
  },
  fixedImage: {
    background: "url(" + image + ")",
    position: "absolute",
    height: "30vh",
    left: 0,
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    width: "100%",
  },
  title: {
    fontFamily: '"chelsea market", fantasy',
    paddingTop: "34vh",
  },
  subtitle: {
    fontSize: "16px",
  },
  teamImageHolder: {
    padding: "0 2vw",
  },
  teamImage: {
    borderRadius: "10px 10px 0 0",
    width: "200px",
    height: "220px",
    backgroundPosition: "center",
    backgroundSize: "cover",
  },
  teamCaption: {
    background: successColor,
    fontSize: "1rem",
    color: "#fff",
    padding: "10px 0",
    textAlign: "center",
    fontWeight: "bold",
  },
};

export default teamStyles;
