import { container } from "assets/jss/material-kit-react.js";
import { roseColor } from "assets/jss/material-kit-react";

const componentsStyle = (theme) => ({
  container,
  brand: {
    color: roseColor,
    textAlign: "left",
    fontFamily: '"chelsea market", fantasy',
    paddingTop: "20vh",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "10vh",
    },
  },
  title: {
    fontSize: "4.2rem",
    fontWeight: "600",
    display: "inline-block",
    position: "relative",
    fontFamily: "inherit",
    textStrokeWidth: "2px",
    textStrokeColor: "#e3e3e3",

    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px 0 40px",
    color: "#fff",
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-40px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.down("sm")]: {
      margin: "-60px 0px 0px",
    },
  },
  link: {
    textDecoration: "none",
  },
  textCenter: {
    textAlign: "center",
  },
  loaderBg: {
    display: "flex",
    justifyContent: "center",
    background: "#fff",
    height: "100vh",

    alignContent: "center",
  },
  loaderVideoContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  loaderVideo: {
    width: "400px",
  },
});

export default componentsStyle;
