import { container } from "assets/jss/material-kit-react";

const dogStyles = {
  section: {
    background: "#EEEEEE",
    padding: "70px 0",
  },
  container,
  textLeft: {
    textAlign: "left",
  },
  textSmall: {
    fontSize: "0.8em",
  },
  textName: {
    fontSize: "1.2rem",
    fontWeight: "bold",
    display: "flex",
    textAlign: "left",
    margin: 0,
    paddingRight: "20px",
    flexGrow: 1,
  },
  adoptionSection: {
    paddingTop: "20px",
  },
  dogGender: {
    paddingRight: "8px",
  },
  dogCards: {
    display: "flex",
    cursor: "pointer",
    width: "300px",
    height: "450px",
    flexDirection: "column",
    margin: "15px",
    borderRadius: "20px 20px 0 0",
  },
  dogBackgroundImage: {
    flexGrow: 1,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px 10px 0 0",
    backgroundPosition: "center",
  },
  dogCardContent: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "0 0 10px 10px",
  },
  dogList: {
    display: "flex",
    justifyContent: "center",
    flexFlow: "wrap",
    padding: "50px 0",
  },
  dogPick: {
    position: "absolute",
    display: "flex",
    alignSelf: "end",
    margin: "10px",
  },
  viewMoreButton: {
    display: "flex",
    justifyContent: "center",
  },
  viewMoreButtonText: {
    fontSize: "1.2rem",
  },
  dogListTitle: {
    fontFamily: '"chelsea market", fantasy',
  },
};

export default dogStyles;
