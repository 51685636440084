import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import useTheme from "@material-ui/core/styles/useTheme";

// @material-ui/icons
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";

import modalStyles from "assets/jss/material-kit-react/views/componentsSections/javascriptStyles.js";
import classNames from "classnames";

import { DONATE_URL } from "config/";

const useModalStyles = makeStyles(modalStyles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const Donate = ({ donateOpenModal, setDonateOpenModal, iframeUrlOverride }) => {
  const classes = useModalStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme?.breakpoints?.down("sm"));

  return (
    <Dialog
      classes={{
        root: classes.center,
        paper: classes.modal,
      }}
      open={donateOpenModal}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      keepMounted
      onClose={() => setDonateOpenModal(false)}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        className={classes.modalHeader}
      >
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setDonateOpenModal(false)}
        >
          <Close className={classes.modalClose} />
        </IconButton>
        <h4 className={classes.modalTitle}>{name}</h4>
      </DialogTitle>
      <DialogContent
        id="classic-modal-slide-description"
        className={classNames(classes.modalBody, classes.donateBody)}
      >
        <iframe
          src={iframeUrlOverride || DONATE_URL}
          width="100%"
          height="615px"
          style={{ maxWidth: "600px", minHeight: "75vh", height: "auto" }}
          name="givebutter"
          frameBorder="0"
          seamless
          allowpaymentrequest
        ></iframe>
        <script src="https://givebutter.com/js/widget.js"></script>
      </DialogContent>
    </Dialog>
  );
};

export default Donate;
