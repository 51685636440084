/*eslint-disable*/
import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import {
  Apps,
  Event,
  ContactSupport,
  Favorite,
  Home
} from "@material-ui/icons";
import AttachMoney from "@material-ui/icons/Event";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import Donate from "components/Donate/Donate";
import { ADOPT_FORM_URL, FOSTER_FORM_URL } from "config/";

const useStyles = makeStyles(styles);

export default function HeaderLinks({ scrollToElement, donateAction }) {
  const classes = useStyles();
  const [ctx, setCtx] = useState("foster");
  const [showModal, setShowModal] = useState(false);

  const formUrl = ctx === "adopt" ? ADOPT_FORM_URL : FOSTER_FORM_URL;

  return (
    <>
      <Donate 
        iframeUrlOverride={formUrl}
        donateOpenModal={showModal}
        setDonateOpenModal={setShowModal} 
      />
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-facebook"
            title="Follow us on facebook"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.facebook.com/Passion-for-Pitties-Rescue-group-178092008970246"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-facebook"} />
            </Button>
          </Tooltip>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Tooltip
            id="instagram-tooltip"
            title="Follow us on instagram"
            placement={window.innerWidth > 959 ? "top" : "left"}
            classes={{ tooltip: classes.tooltip }}
          >
            <Button
              color="transparent"
              href="https://www.instagram.com/passionforpitties/"
              target="_blank"
              className={classes.navLink}
            >
              <i className={classes.socialIcons + " fab fa-instagram"} />
            </Button>
          </Tooltip>
        </ListItem>
        
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Adopt"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Favorite}
            dropdownList={[
              <Link
                onClick={() => scrollToElement("adopt-link")}
                className={classes.dropdownLink}
              >
                See pets
              </Link>,
              <Link
                onClick={() => {setCtx("adopt"); setShowModal(true);}}
                className={classes.dropdownLink}
              >
                Apply
              </Link>
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="Foster"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Home}
            dropdownList={[
              <Link
                onClick={() => scrollToElement("foster-link")}
                className={classes.dropdownLink}
              >
                See pets
              </Link>,
              <Link
                onClick={() => {setCtx("foster"); setShowModal(true);}}
                className={classes.dropdownLink}
              >
                Apply
              </Link>
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            buttonText="About"
            buttonProps={{
              className: classes.navLink,
              color: "transparent"
            }}
            buttonIcon={Apps}
            dropdownList={[
              <Link
                onClick={() => scrollToElement("story-link")}
                className={classes.dropdownLink}
              >
                Our Story
              </Link>,
              <Link
                onClick={() => scrollToElement("team-link")}
                className={classes.dropdownLink}
              >
                Our Team
              </Link>,
              <Link
                onClick={() => scrollToElement("partners-link")}
                className={classes.dropdownLink}
              >
                Our Partners
              </Link>,
              <Link
                onClick={() => scrollToElement("happy-tails-link")}
                className={classes.dropdownLink}
              >
                Success Stories
              </Link>
            ]}
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            onClick={() => scrollToElement("events-link")}
            color="transparent"
            className={classes.navLink}
          >
            <Event /> Events
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            onClick={donateAction}
            color="transparent"
            className={classes.navLink}
          >
            <AttachMoney /> Donate
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            onClick={() => scrollToElement("contact-link")}
            color="transparent"
            className={classes.navLink}
          >
            <ContactSupport /> Contact
          </Button>
        </ListItem>
      </List>
    </>
  );
}
