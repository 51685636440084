import { container, infoColor } from "assets/jss/material-kit-react.js";

const storyStyle = {
  spotlightBg: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    maskImage:
      "linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.25)), linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.25))",
    maskSize: "100% 100%",
    maskRepeat: "no-repeat",
    maskPosition: "center top, center bottom",
    height: "80vh",
    width: "100%",
    position: "absolute",
  },
  container: {
    ...container,
    position: "relative",
    paddingTop: "2vh",
  },
  title: {
    fontFamily: '"chelsea market", fantasy',
    color: infoColor,
    textStrokeWidth: "1px",
    textStrokeColor: "#555",
  },
  content: {
    fontSize: "16px",
  },
};

export default storyStyle;
