import { container } from "assets/jss/material-kit-react.js";

const eventsStyle = {
  container: {
    ...container,
    paddingTop: "4vh",
    zIndex: "2",
    position: "relative",
  },
  title: {
    fontFamily: '"chelsea market", fantasy',
  },
  subtitle: {
    fontSize: "16px",
  },
  photos: {},
  card: {
    padding: "20px",
    height: "260px",
    cursor: "pointer",
  },
  img: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    backgroundPosition: "center",
  },
  name: {
    marginTop: "4px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "16px",
  },
  eventModal: {
    height: "80vh",
    padding: "5vh",
    color: "#fff",
  },
  modalEventImg: {
    minHeight: "50vh",
  },
  modalCloseButton: {
    marginRight: "auto",
    marginLeft: "auto",
    float: "right",
    bottom: "50px",
  },
  modalClose: {
    height: "35px",
    width: "35px",
  },
};

export default eventsStyle;
